<template>
  <div class="container">
    <div class="index_section">
        <van-nav-bar
          title="联系人管理"
          left-text="返回"
          left-arrow
          right-text="新建"
          @click-left="onClickLeft"
          @click-right="onClickright"
        />
        <div>
          <div class="serchBack"></div>
          <div style="padding-top: 50px; height: 50px;" >
            <form action="/">
              <van-search
                v-model.trim="query.name"
                show-action
                placeholder="请输入联系人姓名"
                @search="onSearch"
              >
                <template #action>
                  <div @click="onSearch">搜索</div>
                </template>
              </van-search>
            </form>
          </div>
        </div>
        <div v-if="showEmpty">
            <van-empty image="search" description="哎呀!  没有找到相关信息..." />
        </div>
        <div style="padding-top: 10px;">
          <div class="iptF_cs" v-for="(item, index) in linkList" :key="index">
              <div style="margin-bottom: 10px;">
                  <van-swipe-cell>
                      <van-cell class="cell_cs" style="background-color: white;" is-link/>
                        <template #default>
                            <van-row>
                              <div style="display: inline-block; width: 75px; float: left;" class="hreader_icon">
                              </div>
                                  <van-col span="16">                       
                                    <p class="person_cs">姓名: {{item.name}}</p>
                                    <p class="person_cs">电话: {{item.phone}}</p>
                                    <p class="person_cs">职务: {{item.jobTitle}}</p>
                                  </van-col>
                                <div style="display: inline-block; width: 10px; float: right; color: #e69329;">
                                    <van-icon name="arrow" style="line-height: 80px; float: right; margin-right: 10px;"/>
                                </div>
                            </van-row>
                        </template>
                        <template #right>
                            <van-button square type="primary" @click="setLink(item.id,index)" text="编辑" />
                            <van-button square type="danger" @click="deleteAdmin(item.id,index)" text="删除" />
                        </template>
                  </van-swipe-cell>
              </div>
          </div>      
        </div>
    </div>
  </div>
</template>

<script>
import { Notify } from 'vant';
import { Dialog } from 'vant';
import { Toast } from 'vant';
export default {
  data() {
    return {
      showEmpty: false,
      linkList: [],
      query:{
          pageIndex: 1,
          pageSize: 10,
          name: '',
      },

    }
  },
  created(){
    Toast.loading({
      message: '加载中...',
      overlay: true,
      duration: 200,
      forbidClick: true,
    });
    setTimeout(()=>{
      this.getLinkmanList();
    },100)
  },
  methods: {
      // 编辑联系人 
      setLink(item,index){
        this.$router.push({
          path: '/updateAdmin',
          query: {
            id: item
          }
        })
      },
      // 返回
      onClickLeft() {
        this.$router.push({
          path: '/mine',
          query: {}
        })
      },
      // 跳转至创建联系人界面
      onClickright(){
        this.$router.push({
          path: '/updateAdmin',
          query: {}
        })
      },
      deleteAdmin(row, index){
        Dialog.confirm({
          title: '删除联系人',
          message: '您确定要删除该联系人吗?',
        })
        .then(() => {
          this.$http
          .ajax('/ffkj-main/merchantOtherContact/merchantOtherContactDelete',{
                id: row
            },'delete',{}).then(res=>{
                if( res.code == 0 ){
                    Notify({ type: 'success', message: '删除成功' })
                    this.onSearch();
                }else{
                   Notify({ type: 'danger', message: res.message })
                }
            })
        })
        .catch(() => {
          // on cancel
        });
      },
      // 搜索
      onSearch(){
        Toast.loading({
          message: '加载中...',
          overlay: true,
          duration: 400,
          forbidClick: true,
        });
        this.query.pageIndex = 1;
        setTimeout(()=>{
          this.getLinkmanList();
        },300)
      },
      // 联系人列表数据回显
      getLinkmanList(){
        this.$http
        .ajax('/ffkj-main/merchantOtherContact/pageMerchantOtherContactList',{
            pageNum: this.query.pageIndex,
            pageSize: this.query.pageSize,
            name: this.query.name ? this.query.name.trim() : '',
            phone: '',
            merchantId: localStorage.getItem('merchantId')
        },'get',{}).then(res=>{
            if( res.code == 0 && res.data.list ){
              if(res.data.list.length <= 0){
                this.showEmpty = true;
                this.linkList = [];
              }else{
                this.showEmpty = false;
                this.linkList = res.data.list;
              }
              
            }
            else{
                Notify({ type: 'danger', message: res.message })
            }
            var timer = setTimeout(function(){
                window.clearTimeout(timer)
            }.bind(this), 300)
        })
      }
  }
}
</script>
<style scoped>
</style>